'use client';

import { useCallback } from 'react';
import NextImage, { ImageProps } from 'next/image';
import imageLoader from '@/utils/imageLoader';
import useRatio from './useRatio';

interface ImgProps extends ImageProps {
  ratio?: any;
  fit?: any;
}

export default function Image(props: ImgProps) {
  const ratio = useRatio({ ratio: props?.ratio });

  const loader = useCallback(
    (imgProps: any) =>
      imageLoader({
        ...imgProps,
        ratio,
      }),
    [ratio],
  );

  if (!ratio && !props?.unoptimized) return null;

  return (
    <>
      <NextImage
        {...props}
        alt={props.alt || 'door-builder-canada'}
        style={{
          objectFit: props?.fit || 'cover',
        }}
        loader={loader}
      />
      {props.fill && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            width: '100%',
            height: '100%',
            zIndex: 1,
            backgroundColor: 'transparent',
          }}
        />
      )}
    </>
  );
}
