'use client';

import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useState, useCallback } from 'react';
import { Links } from '.';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const buttonStyles = {
  color: 'text.primary',
  borderBottom: '2px solid',
  borderRadius: 0,
};

export default function DesktopMenu({ links }: { links: Links[] }) {
  const pathname = usePathname();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openMenuIndex, setOpenMenuIndex] = useState<number | null>(null);

  const handleMenuClick = useCallback((event: React.MouseEvent<HTMLElement>, index: number) => {
    setAnchorEl(event.currentTarget);
    setOpenMenuIndex(index);
  }, []);

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
    setOpenMenuIndex(null);
  }, []);

  if (!links?.length) return null;

  return (
    <Stack
      direction='row'
      spacing={1}
      sx={{
        alignItems: 'center',
        ml: 'auto',
        display: {
          xs: 'none',
          lg: 'flex',
        },
      }}
    >
      {links.map((item, index) => {
        if (item.type === 'menu' && item.links?.length) {
          const selected = item.links.some((link) => pathname.startsWith(link.href));

          return (
            <div key={index}>
              <Button
                variant='text'
                onClick={(e) => handleMenuClick(e, index)}
                endIcon={<KeyboardArrowDownIcon />}
                sx={{
                  ...buttonStyles,
                  borderColor: selected ? 'secondary.main' : 'transparent',
                }}
              >
                {item.title}
              </Button>
              <Menu anchorEl={anchorEl} open={openMenuIndex === index} onClose={handleMenuClose}>
                {item.links.map((link, linkIndex) => (
                  <MenuItem
                    key={linkIndex}
                    component={Link}
                    href={link.href}
                    onClick={handleMenuClose}
                    selected={pathname.startsWith(link.href)}
                  >
                    {link.title}
                  </MenuItem>
                ))}
              </Menu>
            </div>
          );
        }

        return (
          <Button
            key={index}
            variant='text'
            sx={{
              ...buttonStyles,
              borderColor: pathname.startsWith(item.href!) ? 'secondary.main' : 'transparent',
            }}
            LinkComponent={Link}
            href={item.href!}
          >
            {item.title}
          </Button>
        );
      })}
    </Stack>
  );
}
