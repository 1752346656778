import { useMemo } from 'react';
import { Breakpoint, Theme, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

type BreakpointOrNull = Breakpoint | null;

export default function useRatio({ ratio }: { ratio: string }) {
  const theme: Theme = useTheme();
  const keys = useMemo(() => [...theme.breakpoints.keys].reverse(), [theme.breakpoints.keys]);

  const width: any = keys.reduce((output: BreakpointOrNull, key: Breakpoint) => {
    const matches = useMediaQuery(theme.breakpoints.up(key)); // eslint-disable-line react-hooks/rules-of-hooks
    return !output && matches ? key : output;
  }, null);

  const aspectRatio = useMemo(() => {
    if (!ratio) return null;

    if (typeof ratio === 'string') return ratio;

    return ratio?.[width] || ratio;
  }, [ratio, width]);

  return aspectRatio;
}
