'use client';

import React, { useEffect, useState, useMemo } from 'react';
import { signOut } from '@/lib/firebase/auth/utils';
import { getMenuItems } from '@/constants/menuItems';
import { CUSTOMER_ROLE } from '@/constants/roles';
import useAuth from '@/hooks/useAuth';
import Dialog from '@/components/common/Dialog';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import LogoutIcon from '@mui/icons-material/Logout';
import Link from 'next/link';
import Button from '@mui/material/Button';
import AuthenticationForm from '@/components/auth/AuthenticationForm';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';

export default function AccountAvatar() {
  const { userData, loaded, role } = useAuth();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const menuItems = useMemo(() => getMenuItems(role || CUSTOMER_ROLE), [role]);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleSignOut = () => {
    signOut();
    handleMenuClose();
  };

  useEffect(() => {
    setAnchorEl(null);
  }, [userData]);

  if (userData) {
    return (
      <>
        <IconButton onClick={handleMenuOpen} size='small'>
          <Avatar
            src={userData.photoURL || undefined}
            sx={{
              width: 36,
              height: 36,
              bgcolor: 'secondary.main',
              color: 'secondary.contrastText',
            }}
          >
            {userData.email?.[0].toUpperCase()}
          </Avatar>
        </IconButton>
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
          <MenuItem
            sx={{
              pointerEvents: 'none',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <Typography
              variant='subtitle1'
              sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', gap: 1 }}
            >
              <span>{userData.displayName || 'User'}</span>
              <Chip label={userData.role} size='small' />
            </Typography>
            <Typography variant='body2' color='text.secondary'>
              {userData.email}
            </Typography>
          </MenuItem>

          <Divider />

          {menuItems.map((item) => (
            <MenuItem key={item.label} component={Link} href={item.path} onClick={handleMenuClose}>
              <ListItemIcon>{<item.icon color='secondary' />}</ListItemIcon>
              <ListItemText>{item.label}</ListItemText>
            </MenuItem>
          ))}
          <Divider />
          <MenuItem onClick={handleSignOut} sx={{ color: 'error.main' }} dense={false}>
            <ListItemIcon>
              <LogoutIcon color='error' />
            </ListItemIcon>
            <ListItemText sx={{ color: 'error.main' }}>Sign Out</ListItemText>
          </MenuItem>
        </Menu>
      </>
    );
  }

  return (
    <Dialog trigger={<Button loading={!loaded}>Sign In</Button>} title='Sign In'>
      <AuthenticationForm />
    </Dialog>
  );
}
