const sanityConfig = {
  projectId: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID!,
  dataset: process.env.NEXT_PUBLIC_SANITY_DATASET!,
  apiVersion: process.env.NEXT_PUBLIC_SANITY_API_VERSION!,
};

interface ImageLoaderProps {
  src: string;
  width?: number;
  quality?: number;
  ratio?: string;
}

export default function imageLoader({ src, width = 48, quality = 70, ratio }: ImageLoaderProps) {
  if (!src) return '';

  if (src.startsWith('./')) return src;

  const height = ratio ? Math.floor(width / eval(ratio)) : 0;
  const transform = `w=${width}${height > 0 ? `&h=${height}` : ''}&q=${quality}&fit=crop&auto=format`;

  if (src.includes('https')) return `${src}?${transform}`;

  const ref = src?.split('-');
  const ext = ref.pop();
  ref.shift();
  const refStr = ref.join('-');

  return `https://cdn.sanity.io/images/${sanityConfig.projectId}/${sanityConfig.dataset}/${refStr}.${ext}?${transform}`;
}
