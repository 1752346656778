'use client';

import { useState, MouseEvent } from 'react';
import { usePathname } from 'next/navigation';
import { Links } from '.';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import Link from 'next/link';

export default function MobileMenu({ links }: { links: Links[] }) {
  const pathname = usePathname();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-label='more'
        id='long-button'
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup='true'
        onClick={handleClick}
        sx={{
          display: {
            xs: 'flex',
            lg: 'none',
          },
          color: '#000',
          ml: 'auto',
        }}
      >
        <MenuIcon fontSize='medium' />
      </IconButton>
      <Menu
        id='long-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ '& .MuiPaper-root': { width: '100%' } }}
        slotProps={{
          list: {
            'aria-labelledby': 'long-button',
          },
        }}
      >
        {links?.map((link, index) => {
          if (link.type === 'link') {
            return (
              <MenuItem
                selected={pathname.startsWith(link.href ?? '')}
                onClick={handleClose}
                key={index}
                component={Link}
                href={link.href ?? ''}
              >
                {link.title}
              </MenuItem>
            );
          }

          return [
            <MenuItem key={index}>{link.title}</MenuItem>,
            ...(link?.links?.map(({ title, href }, subIndex) => (
              <MenuItem
                key={`${index}-${subIndex}`}
                onClick={handleClose}
                component={Link}
                sx={{
                  ml: 2,
                  color: 'text.secondary',
                  borderLeft: '2px solid',
                  borderColor: 'divider',
                }}
                href={href}
              >
                {title}
              </MenuItem>
            )) ?? []),
          ];
        })}
      </Menu>
    </>
  );
}
